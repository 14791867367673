import { Injectable } from "@angular/core";
import { IOrderEngineSpecModel, OrderEngineSpecModel, OrderEngineSpecTypes } from "../models";
import { sortBy } from "sort-by-typescript";

@Injectable()
export class OrderEngineSpecsService {

	getOrderEngineSpecs(): IOrderEngineSpecModel[] {
		const specs: IOrderEngineSpecModel[] = [];

		specs.push(this.addSpec("AttachSlider", OrderEngineSpecTypes.string, ['True', 'False']));
		specs.push(this.addSpec("BenchBrackets", OrderEngineSpecTypes.number));
		specs.push(this.addSpec("BondWire", OrderEngineSpecTypes.string, ['True', 'False']));
		specs.push(this.addSpec("BoxPerimeterEncapsulation", OrderEngineSpecTypes.string, ['True', 'False']));
		specs.push(this.addSpec("CompanyName", OrderEngineSpecTypes.string));
		specs.push(this.addSpec("CoverGuideLengthFeet", OrderEngineSpecTypes.number));
		specs.push(this.addSpec("CoverGuideLengthInches", OrderEngineSpecTypes.number));
		specs.push(this.addSpec("CoverGuideSpaceFeet", OrderEngineSpecTypes.number));
		specs.push(this.addSpec("CoverGuideSpaceInches", OrderEngineSpecTypes.number));
		specs.push(this.addSpec("CoverOptions", OrderEngineSpecTypes.string, ['None', 'Std Mesh Panel (leading edge)', 'Custom Mesh Panel', '1" Set back corner', '2" Setback Corners', '4" Setback Corners', 'Winged Corner']));
		specs.push(this.addSpec("CutTubesExact", OrderEngineSpecTypes.string, ['True', 'False']));
		specs.push(this.addSpec("ClipOnCopingType", OrderEngineSpecTypes.string, ['None', 'Rounded', 'Bullnose']));
		specs.push(this.addSpec("DeckType", OrderEngineSpecTypes.string, ['Concrete', 'Pavers', 'Wood']));
		specs.push(this.addSpec("DeckMountPolyHousings", OrderEngineSpecTypes.string, ['True', 'False']));
		specs.push(this.addSpec("DropToWater", OrderEngineSpecTypes.number));
		specs.push(this.addSpec("EncapsulationType", OrderEngineSpecTypes.string, ['Vinyl Liner', 'Fiberglass', 'Gunite', 'Pool-in-Pool']));
		specs.push(this.addSpec("FabricColor", OrderEngineSpecTypes.string, ['Royal Blue', 'Navy', 'Charcoal', 'Gray', 'Tan', 'Mocha']));
		specs.push(this.addSpec("FeatureController", OrderEngineSpecTypes.string, ['True', 'False']));
		specs.push(this.addSpec("GuideLengthFeet", OrderEngineSpecTypes.number));
		specs.push(this.addSpec("GuideLengthInches", OrderEngineSpecTypes.number));
		specs.push(this.addSpec("GuideSpaceFeet", OrderEngineSpecTypes.number));
		specs.push(this.addSpec("GuideSpaceInches", OrderEngineSpecTypes.number));
		//specs.push(this.addSpec("GuideType", OrderEngineSpecTypes.string, ['Gunite Encapsulation', 'Fiberglass Encapsulaton', 'Vinyl Encapsulation', 'Pool-in-Pool Encapsulation', 'Screw-on Underguide', 'Topguide']));
		specs.push(this.addSpec("GuideType", OrderEngineSpecTypes.string, ['Encapsulated Underguide', 'Topguide', 'Screw-On Underguide', 'Deck Mount']));
		specs.push(this.addSpec("HousingMountingKit", OrderEngineSpecTypes.string, ['Vinyl Liner', 'Fiberglass', 'Free Standing']));
		specs.push(this.addSpec("HousingType", OrderEngineSpecTypes.string, ['None', 'Fiberglass', 'Polymer']));
		specs.push(this.addSpec("HydromotorSize", OrderEngineSpecTypes.number));
		specs.push(this.addSpec("IncludeClipOnCoping", OrderEngineSpecTypes.string, ['True', 'False']));
		specs.push(this.addSpec("IncludePolymerHousing", OrderEngineSpecTypes.string, ['True', 'False']));
		specs.push(this.addSpec("LeadingEdgeBarType", OrderEngineSpecTypes.string, ['2 ½ Rectangle', 'Spliced 2 ½ Rectangle']));
		specs.push(this.addSpec("LeadingEdgeDiameter", OrderEngineSpecTypes.number));
		specs.push(this.addSpec("LidBrackets", OrderEngineSpecTypes.number));
		specs.push(this.addSpec("LidType", OrderEngineSpecTypes.string, ['None', 'Flush Lid', 'Flat Lid', 'Stone Lid']));
		specs.push(this.addSpec("MainLidFeet", OrderEngineSpecTypes.number));
		specs.push(this.addSpec("MainLidInches", OrderEngineSpecTypes.number));
		specs.push(this.addSpec("MotorEndLidFeet", OrderEngineSpecTypes.number));
		specs.push(this.addSpec("MotorEndLidInches", OrderEngineSpecTypes.number));
		specs.push(this.addSpec("MotorSide", OrderEngineSpecTypes.string, ['Left', 'Right', 'Unknown']));
		specs.push(this.addSpec("MotorType", OrderEngineSpecTypes.string, ['Standard', 'Heavy Duty (30rpm)']));
		specs.push(this.addSpec("NonMotorEndLidFeet", OrderEngineSpecTypes.number));
		specs.push(this.addSpec("NonMotorEndLidInches", OrderEngineSpecTypes.number));
		specs.push(this.addSpec("OEM", OrderEngineSpecTypes.string, ['Integra', 'Coverstar', 'Coverpools', 'Coverpools T4 Dual Race', 'Coverpools T3 Adj Glider Undertrack, Snap top or Universal Glider', 'Coverpools T3 Non-ADJ Glider Undertrack', 'Coverpools T4 Under track California corner', 'Coverpools T3 Under track California corner', 'Aquamatic', 'PCS', 'APC']));
		specs.push(this.addSpec("OrderType", OrderEngineSpecTypes.string, ['System', 'Replacement Cover', 'MR']));
		specs.push(this.addSpec("PoolType", OrderEngineSpecTypes.string, ['Fiberglass', 'Gunite', 'Vinyl Liner']));
		specs.push(this.addSpec("PreShippedAllPhase1", OrderEngineSpecTypes.string, ['True', 'False']));
		specs.push(this.addSpec("PreShippedCoping", OrderEngineSpecTypes.string, ['True', 'False']));
		specs.push(this.addSpec("PreShippedEncapsulation", OrderEngineSpecTypes.string, ['True', 'False']));
		specs.push(this.addSpec("PreShippedHousing", OrderEngineSpecTypes.string, ['True', 'False']));
		specs.push(this.addSpec("PreShippedHousingMount", OrderEngineSpecTypes.string, ['True', 'False']));
		specs.push(this.addSpec("PreShippedLeadingEdgeBar", OrderEngineSpecTypes.string, ['True', 'False']));
		specs.push(this.addSpec("PreShippedLidAndBrackets", OrderEngineSpecTypes.string, ['True', 'False']));
		specs.push(this.addSpec("PreShippedMechanism", OrderEngineSpecTypes.string, ['True', 'False']));
		specs.push(this.addSpec("PreShippedRollUpTube", OrderEngineSpecTypes.string, ['True', 'False']));
		specs.push(this.addSpec("PreShippedTrack", OrderEngineSpecTypes.string, ['True', 'False']));
		specs.push(this.addSpec("PriceLevel", OrderEngineSpecTypes.string));
		specs.push(this.addSpec("IncludeReusableCantileverDeckFormKit", OrderEngineSpecTypes.string, ['True', 'False']));
		specs.push(this.addSpec("RollerWrap", OrderEngineSpecTypes.number));
		specs.push(this.addSpec("RollupTubeType", OrderEngineSpecTypes.string, ['5in Extruded', '6in Extruded', 'Spliced 5in Extruded', 'Spliced 6in Extruded']));
		specs.push(this.addSpec("RopeAttachment", OrderEngineSpecTypes.string, ['No Rope', 'Detachable w/IPC tab', 'Continuous w/IPC tab', 'Continuous', 'Detachable w/3 hole tab', 'Continuous w/3 hole tab']));
		specs.push(this.addSpec("RopeOrLoop", OrderEngineSpecTypes.string, ['Loop', 'Rope']));
		specs.push(this.addSpec("ShipAll", OrderEngineSpecTypes.string, ['True', 'False']));
		specs.push(this.addSpec("ShipTubesPhase1", OrderEngineSpecTypes.string, ['True', 'False']));
		specs.push(this.addSpec("ShipPhase1", OrderEngineSpecTypes.string, ['True', 'False']));
		specs.push(this.addSpec("Slack", OrderEngineSpecTypes.number));
		specs.push(this.addSpec("SliderType", OrderEngineSpecTypes.string, ['No Slider', 'IPC Slider', 'IPC Slider (PCS Modified)', 'CoverPools Replacement Slider Assembly', 'Aquamatic Replacement Slider Assembly', 'Coverstar Replacement Slider 403 Assembly', 'Coverstar Replacement Slider 801 Assembly']));
		specs.push(this.addSpec("SwitchType", OrderEngineSpecTypes.string, ['Standard', 'Touchpad']));
		specs.push(this.addSpec("Total3SidesInches", OrderEngineSpecTypes.number));
		specs.push(this.addSpec("TotalCoverGuideLengthInches", OrderEngineSpecTypes.number));
		specs.push(this.addSpec("TotalCoverGuideSpaceInches", OrderEngineSpecTypes.number));
		specs.push(this.addSpec("TotalGuideLengthInches", OrderEngineSpecTypes.number));
		specs.push(this.addSpec("TotalGuideSpaceInches", OrderEngineSpecTypes.number));
		specs.push(this.addSpec("TotalPerimeterInches", OrderEngineSpecTypes.number));
		specs.push(this.addSpec("UnitLocation", OrderEngineSpecTypes.string, ['Underguide', 'Recessed Topgude', 'Deck Mount']));
		specs.push(this.addSpec("VerifyGuideSpaceLength", OrderEngineSpecTypes.string, ['True', 'False']));
		specs.push(this.addSpec("VinylLinerRadiusCorner", OrderEngineSpecTypes.string, ['90 Degree (No Corner)', '6in Radius', '2ft Radius']));
		specs.push(this.addSpec("WalkOnLidBracketAnchoring", OrderEngineSpecTypes.string, ['Box Perimeter Hook', 'Stainless Wedge Anchor', 'None']));
		specs.push(this.addSpec("WalkOnLidBracketExtension", OrderEngineSpecTypes.string, ['15-17in', '17-19in', '19-21in', '21-23in', '23-25in Dbl Thick']));
		specs.push(this.addSpec("WalkOnLidEndBrackets", OrderEngineSpecTypes.string, ['True', 'False']));
		specs.push(this.addSpec("WebbingType", OrderEngineSpecTypes.string, ['Color Matched Heat Sealed', 'White Heat sealed', 'Sewn Dacron White', 'Color Matched Dacron']));

		return specs;
	}

	private addSpec(specName: string, specType: OrderEngineSpecTypes, specValues: string[] = null): IOrderEngineSpecModel {
		const picklistSpec = new OrderEngineSpecModel();
		picklistSpec.specName = specName;
		picklistSpec.specType = specType;
		specValues = specValues || [];
		if (specValues) {
			if (specValues.findIndex(x => x === '') >= 0)
				alert(`${specName} has a blank value`);
			picklistSpec.specValues = [...specValues.sort(sortBy("^"))];
		}

		return picklistSpec;
	}
}