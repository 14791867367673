import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart } from "@angular/router";
import { GlobalsService } from "@services";
import { HelpPagesStore } from "@stores";
import { SlickInitService, SlickInitParams } from 'slick-components';
import { environment } from "../environments/environment";

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.css']
})
export class AppComponent implements OnInit {
	isValidLoginPage: boolean = false;
	showPage: boolean = false;

	constructor(private router: Router, private globalsService: GlobalsService, private helpPagesStore: HelpPagesStore) {
		GlobalsService.isNovaPoolCovers = (window.location.href.indexOf("novapoolcovers.com") >= 0);
	}

	async ngOnInit() {		
		this.showPage = false;

		GlobalsService.isDebug = environment.debug;
		GlobalsService.isStaging = environment.staging;

		const slickInitParams = new SlickInitParams();
		SlickInitService.init(slickInitParams);
		
		this.isValidLoginPage = (window.location.href.indexOf("/login") >= 0);

		if (GlobalsService.isLoggedIn) 
			await this.helpPagesStore.init();		

		this.showPage = true;	

		this.router.events.subscribe(async (event) => {

			if (event instanceof NavigationStart) {
				this.isValidLoginPage = (event.url.indexOf("/login") >= 0) ||
					(event.url.indexOf("/new-company") >= 0);				

				if (GlobalsService.isLoggedIn === false && this.isValidLoginPage === false)
					window.location.href = '/#/login';
				else
					this.showPage = true;
			}
		});
	}
}
