import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { IItemUsageModel, IOrderCountModel } from "@models";
import * as moment from 'moment';

@Injectable()
export class ReportingServices {
	constructor(private httpService: HttpService) {

	}

	async getOrderCountData(startDate: Date, endDate: Date, productionFacility: string, forCompanyId?: number): Promise<IOrderCountModel> {
		const params = {
			startDate: moment(startDate).format("MM/DD/YYYY"),
			endDate: moment(endDate).format("MM/DD/YYYY"),
			productionFacility: productionFacility,
			forCompanyId: (forCompanyId || 0),
		}

		return await this.httpService.get("/reporting/getOrderCountData", params);
	}

	async exportOrderCountToExcel(startDate: Date, endDate: Date, productionFacility: string, forCompanyId?: number): Promise<any> {
		const params = {
			startDate: moment(startDate).format("MM/DD/YYYY"),
			endDate: moment(endDate).format("MM/DD/YYYY"),
			productionFacility: productionFacility,
			forCompanyId: (forCompanyId || 0)
		}

		return Promise.resolve(await this.httpService.get("/reporting/exportOrderCountToExcel", params));
	}

	async getItemUsageData(itemNumber: string, startDate: Date, endDate: Date): Promise<IItemUsageModel> {
		const params = {
			itemNumber: itemNumber,
			startDate: moment(startDate).format("MM/DD/YYYY"),
			endDate: moment(endDate).format("MM/DD/YYYY"),
		}

		return await this.httpService.get("/reporting/getItemUsageData", params);
	}

}