import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';

import { AtatusErrorHandler } from './atatus.handler';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from "../environments/environment";

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from './http.interceptor';

import { HttpErrorDialogComponent } from "./http-error-dialog.component";
import { SlickDialogModule } from "slick-components";
import { ConcurrencyValidationService, GlobalsService, HttpService } from "@services";

// Stores
import { HelpPagesStore, OrderEngineStore, } from "@stores"

import { HashLocationStrategy, LocationStrategy } from '@angular/common';

// Layout
import { LayoutModule } from "./layout/layout.module";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";

@NgModule({
	declarations: [
		AppComponent,
		PageNotFoundComponent,
		HttpErrorDialogComponent		
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		LayoutModule,
		HttpClientModule,
		SlickDialogModule
	],
	providers: [
		HttpService,
		GlobalsService,
		HelpPagesStore,
		OrderEngineStore,
		ConcurrencyValidationService,
		{
			provide: LocationStrategy,
			useClass: HashLocationStrategy
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptor,
			multi: true
		},
		{
			provide: APP_INITIALIZER,
			useFactory: init,
			deps: [GlobalsService],
			multi: true
		}

	],
	bootstrap: [AppComponent]
})
export class AppModule { }

export function init(globalsService: GlobalsService) {
	return async () => {
		const isValidLoginPage = (window.location.href.indexOf("/login") >= 0);

		// Assume not logged in unless the init says otherwise
		GlobalsService.isLoggedIn = false;

		const jwtToken = GlobalsService.jwtToken;
		if (jwtToken && !isValidLoginPage)
			await globalsService.init(jwtToken);

		if (GlobalsService.isLoggedIn === false && isValidLoginPage === false) {
			window.location.href = '/#/login';
			return;
		}
	}
}